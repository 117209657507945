<template>
  <section>
    <slot name="searchPerson"></slot>
    <kn-text-field
      class="mt-4"
      label="Primer nombre"
      v-model="model.primer_nombre"
      :rules="[rules.required]"
      :disabled="disabled"
    />
    <kn-text-field
      label="Segundo nombre"
      v-model="model.segundo_nombre"
      :rules="[]"
      :isMandatory="false"
      :disabled="disabled"
    />
    <kn-text-field
      label="Primer apellido"
      v-model="model.apellido_paterno"
      :rules="[rules.required]"
      :disabled="disabled"
    />
    <kn-text-field
      label="Segundo apellido"
      v-model="model.apellido_materno"
      :rules="[rules.required]"
      :disabled="disabled"
    />
    <kn-text-field
      label="CURP"
      v-model="model.numero_identidad_CURP"
      :rules="[]"
      :isMandatory="false"
      :disabled="disabled"
    />
    <kn-select
      label="Seleccionar sexo"
      v-model="model.genero"
      :rules="[rules.required]"
      :items="genders"
      item-value="id"
      item-text="dato"
      :disabled="disabled"
    />
    <kn-text-field
      label="Fecha de nacimiento"
      v-model="model.fecha_nacimiento"
      colsField="4"
      :rules="[rules.required]"
      type="date"
      hint="DD / MM / YY"
      persistent-hint
      :disabled="disabled"
      @input="setAge(model.fecha_nacimiento)"
    />
    <kn-text-field
      label="Edad"
      v-model="model.edad"
      colsField="4"
      :rules="[]"
      type="number"
      :disabled="true"
    />
    <kn-select
      v-if="isTutor"
      label="Parentesco"
      v-model="model.parentesco"
      :rules="[rules.required]"
      :items="relationship"
      item-value="id"
      item-text="dato"
      :disabled="disabled"
    />
    <kn-check-box
      v-if="isTutor"
      label="Responsable económico:"
      v-model="model.responsable_economico"
      :rules="[]"
      :isMandatory="false"
      :disabled="disabled"
    />
  </section>
</template>

<script>
import { getItem } from '@/api/api-methods';
import { utilsMixin } from '../../shared/mixins/utilsMixin';
import { validationFormMixin } from '../../shared/mixins/validationFormMixin';
import KnTextField from '../../shared/components/KnTextField.vue';
import KnSelect from '../../shared/components/KnSelect.vue';
import KnCheckBox from '../../shared/components/KnCheckBox.vue';
export default {
  components: {
    KnTextField,
    KnSelect,
    KnCheckBox,
  },
  mixins: [utilsMixin, validationFormMixin],
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    isTutor: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      genders: [],
      relationship: [],
    };
  },
  async created() {
    try {
      const [gendersRes, relationshipRes] = await Promise.all([
        getItem(`/app-personas/genero?estatus_sistema=${true}`),
        getItem(`/app-personas/parentesco?estatus_sistema=${true}`),
      ]);
      this.genders = gendersRes.results;
      this.relationship = relationshipRes.results;
    } catch (error) {
      console.error('Error al cargar datos:', error);
    }
  },

  methods: {
    setAge(birthday) {
      this.model.edad = this.calculateAge(birthday);
    },
  },
};
</script>

<style></style>
