<template>
  <section>
    <kn-text-field
      label="Teléfono"
      v-model="model.telefono_movil"
      :rules="[rules.required]"
      :disabled="disabled"
    />
    <kn-text-field
      label="Correo electrónico"
      v-model="model.email"
      :rules="[rules.required, rules.email]"
      :persistent-hint="isUsername"
      :hint="userText"
      :error-messages="errors"
      :disabled="disabled || disabledEmail"
    />
  </section>
</template>

<script>
import { validationFormMixin } from '../../shared/mixins/validationFormMixin';
import KnTextField from '../../shared/components/KnTextField.vue';
import { checkEmailAvailability } from '../helpers/dataOptions';
export default {
  components: { KnTextField },
  mixins: [validationFormMixin],
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    checkEmail: {
      type: Boolean,
      default: false,
    },
    isUsername: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledEmail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: '',
    };
  },
  computed: {
    availability() {
      return this.model.email;
    },
    userText() {
      return this.isUsername
        ? 'El correo será usado como nombre de usuario'
        : '';
    },
  },
  watch: {
    async availability(value) {
      if (this.checkEmail) {
        if (value && value !== '') {
          const { ok } = await checkEmailAvailability(value);
          if (!ok) {
            this.errors = 'El correo ya está en uso';
            this.model.emailErrors = this.errors;
          } else {
            this.errors = '';
          }
        } else {
          this.errors = '';
        }
      }
    },
  },
};
</script>

<style></style>
