import axios from 'axios';

import { getItem } from '../../../api/api-methods';
import { ENVIRONMENT } from '../../../api/config';

const TEST_URL = 'https://apisandbox.facturama.mx/api/catalogs';
const PROD_URL = 'https://api.facturama.mx/catalogs';
const FACTURAMA_URL = ENVIRONMENT === 'prod' ? PROD_URL : TEST_URL;

export const getCredentialsFacturama = async (institution) => {
  const URL = `/app-personas/institucion-educativa/${institution}`;
  try {
    const access = await getItem(URL);
    if (!access || !access.id) throw new Error('Access response is invalid');
    return {
      user: access.usuario_facturama,
      password: access.password_facturama,
    };
  } catch (error) {
    console.error('Error al obtener los accesos', error);
    throw error;
  }
};

export const fetchGetFacturama = async (user, password, url) => {
  try {
    const apiFacturama = axios.create({
      baseURL: FACTURAMA_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Basic ' + Buffer.from(`${user}:${password}`).toString('base64'),
      },
    });
    const res = await apiFacturama.get(url);
    return res.data;
  } catch (error) {
    console.error('Error al obtener los accesos', error);
    throw error;
  }
};
