import { getItem, postItem } from "../../../api/api-methods";
import { fetchGetFacturama, getCredentialsFacturama } from "../../shared/helpers/facturamaUtil";

export const getDefaultTaxInfo = async (institutionId) => {
  const { password, user } = await getCredentialsFacturama(institutionId);
  const usageInvoice = await getUsageInvoiceDefault()

  const [regimentTypes] = await Promise.all([
    fetchGetFacturama(user, password, '/FiscalRegimens'),
  ]);

  const genericTaxInfo = {
    tax_id: 'XAXX010101000',
    nombre_o_razon_social: 'PÚBLICO EN GENERAL',
    regimen_fiscal: regimentTypes.find(item => item.Value === '616'),
    uso_factura: usageInvoice,
  };
  return genericTaxInfo;
}

const getUsageInvoiceDefault = async () => {
  const usageInvoice = await getItem('/app-personas/filters/uso-factura?clave=S01&estatus_sistema=true');
  if (!usageInvoice.results.length) {
    const newItem = postItem('app-personas/uso-factura', {
      dato: 'Sin efectos fiscales',
      clave: 'S01',
    })
    return newItem
  }
  return usageInvoice.results[0]
}