/**
 * Remueve los acentos diacriticos y espacios de
 * un texto
 * @param {string} text texto a limpiar
 * @returns texto sin empacios y sin acento
 */
const normalizeText = (text) => {
  return text
    .replace(/ /g, '')
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

const getRandomInt = (min = 1, max = 100) => {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
};

const completeNumberWithZeros = (number, length) => {
  return String(number).padStart(length, '0');
};

export const utilsMixin = {
  methods: {
    generateUsername({
      primer_nombre: pn,
      segundo_nombre: sn,
      apellido_paterno: ap,
      apellido_materno: am,
      fecha_nacimiento: fena,
    }) {
      const snUser = sn !== null && sn.length > 0 ? sn[0] : '';
      const amUser = am !== null && am.length > 0 ? am[0] : '';
      const splitedFena = fena.split('-');
      const numericUser = `${splitedFena[2]}${splitedFena[1]}`;
      const randomNumber = completeNumberWithZeros(getRandomInt(1, 100), 3);

      const userName = `${pn}${snUser}.${ap}${amUser}${numericUser}${randomNumber}`;
      const userNameClean = normalizeText(userName);
      return userNameClean.toLowerCase();
    },
    generatePassword(
      // eslint-disable-next-line no-unused-vars
      { primer_nombre: pn, fecha_nacimiento: fena },
      length = 10
    ) {
      // const splitedFena = fena.split('-');
      // const userPassword = `${pn}${splitedFena.join('')}`;
      // const userPasswordClean = normalizeText(userPassword);

      // return userPasswordClean.toLowerCase();
      const charset =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=<>?';

      let password = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
      }

      return password;
    },
    generateGenericUsername({ firstName: fn, lastName: ln }, type = '') {
      const splitedFn = fn.split(' ');

      const now = new Date();
      const timeInMili = now.getTime();
      const timeString = timeInMili.toString();
      const numericUser = timeString.substring(8, timeString.length);

      const fnUser = fn !== null && splitedFn.length > 0 ? splitedFn[0] : type;
      const lnUser = ln !== null && ln.length > 0 ? ln[0] : '';
      const randomNumber = completeNumberWithZeros(getRandomInt(1, 100), 3);

      const userName = `${fnUser}${lnUser}${numericUser}${randomNumber}`;
      const userNameClean = normalizeText(userName);
      return userNameClean.toLowerCase();
    },
    generateGenericPassword() {
      const userPasswordClean = `kP_${completeNumberWithZeros(
        getRandomInt(1, 99999),
        5
      )}`;
      return userPasswordClean;
    },
    calculateAge(birthday) {
      if (birthday === null) {
        return '';
      }
      const date = new Date(birthday);
      const today = new Date();
      const age = today.getFullYear() - date.getFullYear();
      return age;
    },
    fullAddress({
      calle: ca,
      numero_exterior: ne,
      numero_interior: ni,
      colonia: col,
      ciudad: ci,
      estado: es,
      codigo_postal: cp,
    }) {
      const fullAddress = `${ca} ${ne}${
        ni ? ' ' + ni : ''
      }, Col. ${col}, ${ci}, ${es}. CP. ${cp.codigo_postal}`;
      return fullAddress;
    },
  },
};
