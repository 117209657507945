import { mapState } from 'vuex';

import {
  fetchGetFacturama,
  getCredentialsFacturama,
} from '../../helpers/facturamaUtil';
import { getItem } from '../../../../api/api-methods';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';

import KnAutoComplete from '../.././../shared/components/KnAutoComplete.vue';
import KnTextField from '../.././../shared/components/KnTextField.vue';
import { getDefaultTaxInfo } from '../../../students/helpers/taxInfoUtils';

export default {
  components: { KnTextField, KnAutoComplete },
  mixins: [validationFormMixin],
  computed: {
    ...mapState(['institutionId', 'userData']),
  },
  props: {
    model: {
      type: Object,
      default: () => { },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    useGeneric: {
      type: Boolean,
    },
    originalTaxInfo:{
      type: Object,
    }
  },
  data() {
    return {
      invoiceUse: [],
      regimentTypes: [],
      currency: [],
      paymentsForms: [],
      CFDIType: [],
      paymentsMethods: [],
      isEditMode: false,
      localIsChecked: this.useGeneric,
    };
  },
  watch: {
    useGeneric(newValue) {
      if (newValue) {
        this.applyGenericInfo();
        this.isEditMode = false
      } else {
        if(!this.originalTaxInfo.id)
          this.isEditMode = true
        this.restoreOriginalInfo(); 
      }
    },
  },
  methods: {

    async fetchFormOptions() {
      try {
        const { password, user } = await getCredentialsFacturama(this.institutionId);
        const usoInvoice = await getItem('/app-personas/uso-factura?limit=30');
        this.invoiceUse = usoInvoice.results;

        const [
          regimentTypes,
          currency,
          paymentsForms,
          CFDIType,
          paymentsMethods,
        ] = await Promise.all([
          fetchGetFacturama(user, password, '/FiscalRegimens'),
          fetchGetFacturama(user, password, '/Currencies?keyword=Peso Mexicano'),
          fetchGetFacturama(user, password, '/PaymentForms'),
          fetchGetFacturama(user, password, '/CfdiTypes'),
          fetchGetFacturama(user, password, '/PaymentMethods'),
        ]);

        this.regimentTypes = regimentTypes;
        this.currency = currency;
        this.paymentsForms = paymentsForms;
        this.CFDIType = CFDIType;
        this.paymentsMethods = paymentsMethods;
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },

    emitChangeInfo() {
      this.$emit('changeGenericInfo', this.regimentTypes, this.invoiceUse);
    },
    emitHandleCheck(value) {
      this.$emit('changeGenericCheck', value);
    },
    emitTaxInfo() {
      this.$emit('update-tax-info', {
        taxInfo: this.model,
        regimentTypes: this.regimentTypes, 
        invoiceUse: this.invoiceUse, 
      });
    },
    async applyGenericInfo() {
      const genericTaxInfo = await getDefaultTaxInfo(this.institutionId)
      this.$emit('update-tax-info', {
        taxInfo: genericTaxInfo,
        regimentTypes: this.regimentTypes,
        invoiceUse: this.invoiceUse,
      });
    },
    restoreOriginalInfo() {
      if (this.originalTaxInfo) {
        this.$emit('update-tax-info', {
          taxInfo: this.originalTaxInfo, 
          regimentTypes: this.regimentTypes,
          invoiceUse: this.invoiceUse,
        });
      }
    },
  },

  async created() {
    await this.fetchFormOptions(); 
    if(this.useGeneric && !this.originalTaxInfo.id)
      this.applyGenericInfo()
  },


};
